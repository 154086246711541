$padding-horizontal-mobile: 100px;
$padding-horizontal-sm: 150px;
$padding-horizontal-md: 200px;
$padding-horizontal: 250px;
$dark: #242536;
$dark-grey: #92929d;
$grey: #e4e4e4;
$black: #4c4d5e;
$light-grey: #f6f6f6;
$blue: #282b57;
$red: #d73b3b;
$red-dark: #c62a2a;