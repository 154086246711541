@import 'variaveis/variaveis';
@import "variaveis/breakpoints";

.header {
    align-items: center;
    background-image: url("/assets/menu/header.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    height: 300px;
    padding: 0px $padding-horizontal;
    @media (max-width: $desktop_xsm) {
      padding: 0px $padding-horizontal-mobile;
    }
    @media (min-width: $desktop_xsm) and (max-width: $desktop_sm) {
      padding: 0px $padding-horizontal-sm;
    }
    @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
      padding: 0px $padding-horizontal-md;
    }
    &__text {
      color: white;
      font-size: 5rem;
      min-width: 400px;
      width: 40%;
    }
  }