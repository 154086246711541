@import "variaveis/variaveis";
@import "variaveis/breakpoints";

$massas: #d73b3b;
$carnes: #30201e;
$combos: #e6c864;
$veganos: #80aa40;

.item {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  &__imagem {
    min-width: 240px;
    @media (max-width: $desktop_xsm) {
      width: 100%;
    }
    img {
      border-radius: 8px;
      width: 100%;
    }
  }
  &__descricao {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 20px;
    @media (max-width: $desktop_xsm) {
      flex-direction: row;
      margin-top: 20px;
      padding: 0;
    }
  }
  &__titulo {
    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 20px;
    }
    p {
      color: $dark-grey;
      font-size: 1.3rem;
      font-weight: 600;
      max-width: 600px;
      margin-bottom: 20px;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 700px;
    @media (max-width: $desktop_xsm) {
      justify-content: flex-end;
    }
    @media (max-width: $mobile) {
      justify-content: flex-start;
    }
    > div {
      margin: 10px;
    }
  }
  &__tipo {
    align-items: center;
    border-radius: 2px;
    display: flex;
    font-weight: bold;
    height: 40px;
    justify-content: center;
    padding: 10px 30px;
    &__massas {
      background-color: $massas;
      color: white;
    }
    &__carnes {
      background-color: $carnes;
      color: white;
    }
    &__combos {
      background-color: $combos;
    }
    &__veganos {
      background-color: $veganos;
    }
  }
  &__porcao,
  &__qtdpessoas,
  &__valor {
    align-items: center;
    display: flex;
    font-size: 1.25rem;
    font-weight: bold;
    justify-content: center;
  }
  &__valor {
    color: $red;
    font-size: 1.7rem;
  }
}